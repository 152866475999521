import request from '@/services/request'
import { NEWS_URL } from '../services/constans'

export const getNewsQuery = async ({ limit = 6, page, id }) => {
  const { data } = await request({
    url: NEWS_URL,
    method: 'get',
    params: {
      page,
      limit,
      'heading.id': id,
    },
  })
  return data
}

export const getNewsItemQuery = async id => {
  const { data } = await request({
    url: `${NEWS_URL}/${id}`,
    method: 'get',
  })
  return data
}
