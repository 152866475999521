import React from 'react'
import Layout from '@/layouts/MainLayout'
import SEO from '@/components/seo'
import { CategoryCascad } from '../cascad-screens/CategoryCascad'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getInfinityQuery } from '../utils/getInfinityQuery'
import { NewsContent } from '../components/NewsContent'
import { useInfiniteQuery } from 'react-query'
import { getNewsQuery } from '../api/news'
import { navigate } from 'gatsby'

const Heading = ({ params }) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isFetched,
  } = useInfiniteQuery(
    ['heading'],
    ({ pageParam }) =>
      getInfinityQuery(getNewsQuery, pageParam, params['*'], 8),
    {
      getNextPageParam: lastPage => lastPage.next,
      onError: () => navigate('/404'),
    }
  )

  const dataLength = data?.pages[data?.pages.length - 1].results.length

  return (
    <Layout>
      <SEO title='Новости' />
      {!isFetched && (isLoading || isFetching) ? (
        <CategoryCascad count={8} hasHeader={false} />
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          style={{ overflow: 'hidden' }}
          loader={
            (isFetchingNextPage || isLoading) && (
              <CategoryCascad count={8} hasHeader={false} />
            )
          }
        >
          <NewsContent
            heading='Новости'
            data={data?.pages}
            dataLength={dataLength}
          />
        </InfiniteScroll>
      )}
    </Layout>
  )
}

export default Heading
